import hospitals from "../utils/hospitals.json"

export const handleLoadHospitalOptions = (inputValue, callback) => {
  let sortedHospitals = [...hospitals]
  sortedHospitals.shift()
  sortedHospitals = sortedHospitals.sort((a, b) =>
    a.value.localeCompare(b.value)
  )
  sortedHospitals = [hospitals[0], ...sortedHospitals]

  callback(
    sortedHospitals
      .filter(x => x.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 50)
  )
}
export const handleOnHospitalChange = fieldName => (
  selectedValue,
  setFieldValue
) => {
  setFieldValue(fieldName, selectedValue)
}
