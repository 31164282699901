import React from "react"
import Img from "gatsby-image"

import Layout from "layout/Layout"
import Container from "layout/Container"

import { useCompletedImages } from "./hooks/useCompletedImages"

const Completed = ({ children, title, seoTitle, icon }) => {
  const images = useCompletedImages()
  const image = icon
    ? images[icon].childImageSharp.fluid
    : images.complete.childImageSharp.fluid

  return (
    <Layout seoTitle={seoTitle}>
      <Container isCentered>
        <h2 className="has-text-centered mb-0 has-text-primary mx-2-mobile pb-0">
          {title}
        </h2>
        <section className="content">
          <center>
            <Container
              isCentered
              mobile={8}
              tablet={6}
              desktop={4}
              fullwidth={4}
            >
              {icon && <Img fluid={image} alt="Success!" />}
            </Container>
          </center>
          {children}
        </section>
      </Container>
    </Layout>
  )
}

export default Completed
