import React, { Fragment, useContext } from "react"

import Message from "elements/Message"
import FileURLPreview from "elements/FileURLPreview"
import FileThumbnail from "elements/Dropzone/FileThumbnail"

import { AppContext } from "../../../context/AppContext"
import { PrescribedMedicinesList } from "components/Eprescription/EprescriptionForm/PrescribedMedicinesSection"

const PrescriptionDetailsSection = () => {
  const { state } = useContext(AppContext)
  const { medicines, id, uploadedFile } = state?.eprescription
  const hasMedicinesPrescribed = medicines?.length > 0

  if (uploadedFile) {
    return (
      <Fragment>
        <FileURLPreview file={uploadedFile} />
      </Fragment>
    )
  }
  if (id)
    return (
      <PrescribedMedicinesList
        hasMedicinesPrescribed={hasMedicinesPrescribed}
        medicines={medicines}
      />
    )
  else if (state?.documents?.length > 0)
    return (
      <div className="px-1 mx-1">
        <div className="columns is-vcentered is-multiline">
          {state?.documents.map(document => (
            <div className="column is-6">
              <FileThumbnail file={document} hasFileName />
            </div>
          ))}
        </div>
      </div>
    )
  return (
    <Message color="warning">
      <p className="has-text-black is-size-6">
        You have not uploaded any documents.
      </p>
    </Message>
  )
}

export default PrescriptionDetailsSection
