import React, { Fragment } from "react"

import Section from "elements/Section"
import FormInput from "elements/Form/FormInput"
import FormDate from "elements/Form/FormDate"

import { ProgramName } from "../../Elements/Brand"

const PatientName = ({ eprescription, patientData }) => {
  const { id } = eprescription

  if (id)
    return (
      <p className="has-text-weight-bold mb-1">
        {patientData?.firstName} {patientData?.lastName}
      </p>
    )
  return (
    <Fragment>
      <FormInput
        name="patient.firstName"
        label="First Name"
        isRequired
        placeholder="Juan"
      />
      <FormInput
        name="patient.lastName"
        label="Last Name"
        isRequired
        placeholder="Dela Cruz"
      />
    </Fragment>
  )
}

const PersonalInformationSection = ({ eprescription, patientData, values }) => {
  const defaultBirthdayValue = {
    month: {
      value: null,
    },
    date: {
      value: null,
    },
    year: "",
  }

  return (
    <Section title="Patient Details">
      <PatientName eprescription={eprescription} patientData={patientData} />
      <FormInput
        name="patient.mobileNumber"
        label="Mobile Number"
        isRequired
        placeholder="09123456789"
      />
      <FormInput
        name="patient.email"
        label="Email"
        isRequired
        placeholder="juan@mail.com"
      />
      <FormDate
        label="Birthday"
        name="patient.birthday"
        values={values?.patient?.birthday || defaultBirthdayValue}
        isRequired
        helper={
          <span>
            You must be at least 18 years old to enroll in the <ProgramName />.
          </span>
        }
      />
    </Section>
  )
}

export default PersonalInformationSection
