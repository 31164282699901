import React from "react"
import { Router } from "@reach/router"
import DeliveryDetails from "components/Epharmacy/DeliveryDetails"
import DeliveryDetailsSummary from "components/Epharmacy/DeliveryDetailsSummary"
import DeliveryDetailsCompleted from "components/Epharmacy/DeliveryDetailsCompleted"
import Patient from "components/StaticPages/Patient"

export default () => {
  return (
    <Router basepath="/patient">
      <DeliveryDetails path="/enroll" />
      <DeliveryDetailsSummary path="/enroll/summary" />
      <DeliveryDetailsCompleted path="/enroll/completed" />
      <Patient path="/" />
    </Router>
  )
}
