import * as Yup from "yup"
import {
  REQUIRED_MESSAGE,
  INVALID_EMAIL,
  INVALID_MOBILE_NUMBER,
  INVALID_YEAR,
  yearNow,
} from "services/validations"

export const contactThroughChoices = ["SMS", "Email", "Call"]

export const validationSchema = Yup.object().shape({
  patient: Yup.object().shape({
    firstName: Yup.string().required(REQUIRED_MESSAGE),
    lastName: Yup.string().required(REQUIRED_MESSAGE),
    mobileNumber: Yup.string()
      .min(11, INVALID_MOBILE_NUMBER)
      .max(11, INVALID_MOBILE_NUMBER)
      .matches(/^09[0-9]{9}$/, INVALID_MOBILE_NUMBER)
      .required(REQUIRED_MESSAGE),
    email: Yup.string()
      .email(INVALID_EMAIL)
      .required(REQUIRED_MESSAGE),
    birthday: Yup.object({
      month: Yup.object().shape({
        value: Yup.string()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      date: Yup.object().shape({
        value: Yup.number()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      year: Yup.number()
        .min(1900, INVALID_YEAR)
        .max(yearNow, INVALID_YEAR)
        .required(REQUIRED_MESSAGE),
    }),
  }),
  prescribingDoctor: Yup.string().required(REQUIRED_MESSAGE),
  patientClinic: Yup.object().shape({
    name: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    otherHospital: Yup.string().when("name", {
      is: name => name?.value === "Other",
      then: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.object().shape({
      value: Yup.string()
        .required("This field is required")
        .nullable(),
    }),
    barangay: Yup.object().shape({
      value: Yup.string()
        .required("This field is required")
        .nullable(),
    }),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    addressType: Yup.string().required("This field is required"),
  }),
  authorizedPerson: Yup.object().shape({
    fullName: Yup.string().required(REQUIRED_MESSAGE),
    contactNumber: Yup.string()
      .min(11, INVALID_MOBILE_NUMBER)
      .max(11, INVALID_MOBILE_NUMBER)
      .matches(/^09[0-9]{9}$/, INVALID_MOBILE_NUMBER)
      .required(REQUIRED_MESSAGE),
  }),
  contactThrough: Yup.array().min(1, REQUIRED_MESSAGE),
})
