import React from "react"
import { Link } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import PatientWelcomeMessage from "elements/PatientWelcomeMessage"
import { ProgramName } from "../Elements/Brand"

const Patient = () => {
  return (
    <Layout
      title={
        <span>
          Welcome to the <ProgramName />
        </span>
      }
      subtitle=""
      seoTitle="Welcome to the Semaglutide (Ozempic®) Early Experience Program"
    >
      <Container isCentered fullhd={6}>
        <PatientWelcomeMessage />
        <div className="buttons my-3 is-centered">
          <Link
            to="/patient/enroll"
            className="button is-primary is-fullwidth is-medium"
          >
            Continue
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export default Patient
