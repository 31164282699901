import React, { useRef, useState } from "react"
import classNames from "classnames"

import Message from "elements/Message"

import styles from "./utils/elements.module.scss"
import { handleFileRead } from "./services/dropzone"

const MAX_FILE_SIZE_IN_BYTES = 3145728
const MAX_FILE_COUNT = 1

const UploadBox = ({ fileCount, children }) => {
  const blurb = fileCount
    ? "+ Add another photo or file"
    : "Take a photo or choose a file"
  return (
    <p
      className={classNames(
        "title my-1 is-size-4 has-text-primary has-text-centered is-flex",
        styles["dropzone__uploadBox"]
      )}
    >
      {children || blurb}
    </p>
  )
}

const Dropzone = ({
  children,
  maxFileCount = MAX_FILE_COUNT,
  maxFileSize = MAX_FILE_SIZE_IN_BYTES,
  currentFilesList,
  docType,
  onUploadSuccess,
}) => {
  const [messages, setMessages] = useState([])
  const fileInputRef = useRef(null)
  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  return (
    <div className={classNames("container my-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={event =>
          handleFileRead({
            event,
            maxFileCount,
            maxFileSize,
            currentFilesList,
            docType,
            onUploadSuccess,
            setMessages,
          })
        }
        multiple
        accept="image/*,.pdf"
        value=""
      />
      <div
        className={classNames("is-flex mb-1", styles["dropzone__container"])}
        onClick={handleFileChooser}
        onKeyDown={event => {
          if (event.key === "Enter") handleFileChooser()
        }}
        role="button"
        tabIndex={0}
      >
        <UploadBox
          // fileCount={filesUploaded.length}
          // uploadIcon={fileUploadSparkle}
          children={children}
        />
      </div>
      {messages?.length > 0 && (
        <Message color="warning">
          <p className="is-size-6 has-text-black">
            <ul>
              {messages.map(message => (
                <li>{message}</li>
              ))}
            </ul>
          </p>
        </Message>
      )}
    </div>
  )
}

export default Dropzone
