import React, { useContext } from "react"
import FormCheckbox from "elements/Form/FormCheckbox"
import Section from "elements/Section"
import Collapsible from "elements/Collapsible"
import { ProgramName } from "elements/Brand"
import { useStaticQuery, graphql } from "gatsby"

import { AppContext } from "../../../context/AppContext"
import { epharmacy } from "../../../context/AppReducer"

const Educare = () => {
  const { state, dispatch } = useContext(AppContext)

  const ALL_EPRESCRIPTION_IMAGES = graphql`
    {
      educareImage: file(relativePath: { eq: "what-is-educare.webp" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
        publicURL
      }
    }
  `

  let data = useStaticQuery(ALL_EPRESCRIPTION_IMAGES)

  const continueReceivingInformation = (
    <span>
      I would like to receive support from EDUCARE as part of the{" "}
      <ProgramName />, including access to nurse educators and patient education
      materials, and consent to the sharing of my enrollment data with EDUCARE
      for this purpose. <span className="is-italic">(Optional)</span>
    </span>
  )

  return (
    <Section title="EDUCARE" id="educare-section">
      <Collapsible title="What is EDUCARE?" color="light" isOpen>
        <img
          className="my-2"
          src={data?.educareImage?.publicURL}
          alt="about educare"
        />
      </Collapsible>
      <div className="mb-2">
        <a
          href="http://bit.ly/2MUH4ow"
          target="_blank"
          className="has-text-weight-bold"
          rel="noopener noreferrer"
        >
          Learn more about EDUCARE
        </a>
      </div>
      <FormCheckbox
        name="continueReceivingInformation"
        values={state?.epharmacy?.continueReceivingInformation}
        options={[continueReceivingInformation]}
        onChange={event =>
          dispatch({
            type: epharmacy.SAVE_DELIVERY_DETAILS,
            payload: {
              continueReceivingInformation: event.target.checked
                ? [continueReceivingInformation]
                : [],
            },
          })
        }
      />
    </Section>
  )
}

export default Educare
