import React, { useContext } from "react"

import Dropzone from "elements/Dropzone"
import DocumentsCardRow from "elements/Dropzone/DocumentsCardRow"

import { AppContext } from "../../context/AppContext"

const UploadPrescriptionSection = ({ docType }) => {
  const { state, dispatch } = useContext(AppContext)

  const handleUploadSuccess = ({ file }) => {
    dispatch({
      type: "SAVE_DOCUMENT",
      payload: file,
    })
  }

  const handleDeleteDocument = index => {
    let tempDocuments = [...state?.documents]
    tempDocuments.splice(index, 1)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })
  }

  if (state?.documents?.length > 0)
    return state?.documents?.map(document => (
      <DocumentsCardRow
        fileName={document.name}
        oldFileName={document.oldname}
        handleDeleteDocument={handleDeleteDocument}
        file={document}
      />
    ))
  return (
    <Dropzone
      currentFilesList={state?.documents}
      docType={docType || "RX"}
      onUploadSuccess={handleUploadSuccess}
    />
  )
}

export default UploadPrescriptionSection
