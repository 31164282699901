import React, { useContext } from "react"
import { Form, Formik } from "formik"
import { navigate } from "gatsby"

import Loading from "elements/Loading"
import Section from "elements/Section"
import FormInput from "elements/Form/FormInput"
import ActionButtons from "elements/ActionButtons"
import DeliveryAddressSection from "./DeliveryAddressSection"
import AuthorizedPersonSection from "./AuthorizedPersonSection"
import PrescriptionDetailsSection from "./PrescriptionDetailsSection"
import PersonalInformationSection from "./PersonalInformationSection"
import CommunicationPreference from "./CommunicationPreference"
import Educare from "./Educare"

import { encrypt } from "../../Auth/services/crypto"
import { epharmacy, patient } from "../../../context/AppReducer"
import { AppContext } from "../../../context/AppContext"
import { validationSchema } from "../utils/deliveryDetailsSchema"

const DeliveryDetailsForm = ({ loading, patientData }) => {
  const { state, dispatch } = useContext(AppContext)
  const { id, doctor } = state?.eprescription
  const backRoute = id
    ? `/view/?e=${encodeURIComponent(encrypt({ data: id }))}`
    : "/"
  const formInitialValues = {
    ...state.epharmacy,
    prescribingDoctor: doctor?.id
      ? `${doctor?.firstName} ${doctor?.lastName}`
      : state?.epharmacy?.prescribingDoctor,
    patient: patientData?.id
      ? {
          ...patientData,
        }
      : {
          ...state?.epharmacy?.patient,
        },
  }

  const handleSubmit = values => {
    if (!id && state.documents.length < 1) return values
    dispatch({ type: epharmacy.SAVE_DELIVERY_DETAILS, payload: values })
    dispatch({ type: patient.SAVE_PATIENT, payload: values.patient })
    navigate("/patient/enroll/summary")
  }

  if (loading) return <Loading />
  return (
    <Formik
      initialValues={{
        ...formInitialValues,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, submitCount, setFieldValue }) => (
        <Form>
          <PersonalInformationSection
            eprescription={state?.eprescription}
            patientData={patientData}
            values={values}
          />
          <Section title="Prescription Details">
            <PrescriptionDetailsSection
              submitCount={submitCount}
              values={values}
            />
            <FormInput
              name="otherDiabetesMedications"
              label="What other diabetes medications are you currently taking?"
            />
          </Section>
          <DeliveryAddressSection
            values={values}
            setFieldValue={setFieldValue}
          />
          <AuthorizedPersonSection />
          <CommunicationPreference values={values} />
          <Educare values={values} />
          <ActionButtons
            submit={{ label: "Review Details" }}
            back={{ label: "Back", link: backRoute }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default DeliveryDetailsForm
