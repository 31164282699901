import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { faUserMd, faHospital } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Section from "elements/Section"
import Layout from "components/Layout/Layout"
import ActionButtons from "elements/ActionButtons"
import Container from "components/Layout/Container"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"
import DeliveryAddressSummary from "./DeliveryDetailsSummary/DeliveryAddressSummary"
import AuthorizedPersonSummary from "./DeliveryDetailsSummary/AuthorizedPersonSummary"
import PrescriptionDetailsSection from "./DeliveryDetailsSummary/PrescriptionDetailsSection"
import PersonalInformationSummary from "./DeliveryDetailsSummary/PersonalInformationSummary"

import { AppContext } from "../../context/AppContext"
import { handleSendOrder } from "./services/epharmacy"
import { RESET_STATE, toast } from "../../context/AppReducer"
import { shouldPatientButtonBeDisabled } from "./services/epharmacy"
import patientConsentAndAuth from "./utils/patientConsentAndAuth.json"
import { epharmacyOrderZendeskTemplate } from "./templates/epharmacyOrderZendeskTemplate"

const DeliveryDetailsSummary = () => {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const { doctor } = state?.eprescription
  const { prescribingDoctor, patientClinic } = state?.epharmacy

  useEffect(() => {
    const checkIfDisabled = async () => {
      let isFormValid = await shouldPatientButtonBeDisabled({
        formData: state?.epharmacy,
      })
      setIsDisabled(isFormValid)
    }
    checkIfDisabled()
  })

  const handleSuccessCallback = () => {
    setLoading(false)
    dispatch({ type: RESET_STATE })
    navigate("/patient/enroll/completed")
  }

  const handleErrorCallback = () => {
    dispatch({
      type: toast.SHOW_TOAST,
      payload: {
        message:
          "There was a problem in sending your enrollment. Please try again.",
        color: "danger",
      },
    })
    setLoading(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    await handleSendOrder({
      fileArray: [...state.documents],
      ticketTemplate: epharmacyOrderZendeskTemplate,
      values: {
        ...state.epharmacy,
        uploadedFile: state?.eprescription?.uploadedFile,
        doctor,
      },
      callback: handleSuccessCallback,
      errorCallback: handleErrorCallback,
    })
  }

  return (
    <Layout
      title="Enrollment Summary"
      subtitle={
        <span>
          Please review if the following information is correct.
          <br />
          Your information will be used as a basis for your enrollment.
        </span>
      }
      seoTitle="Enrollment Summary"
    >
      <Container isCentered desktop={6} fullhd={6}>
        <Section title="Patient Details">
          <PersonalInformationSummary />
          <DeliveryAddressSummary />
          <AuthorizedPersonSummary />
          <p className="mt-1 mb-1-mobile">
            Prescribing Doctor
            <section className="has-text-weight-bold">
              <FontAwesomeIcon icon={faUserMd} className="mr-1" />
              {doctor?.id
                ? `${doctor?.firstName} ${doctor?.lastName}`
                : prescribingDoctor}
            </section>
            <section>
              <FontAwesomeIcon icon={faHospital} className="mr-1" />
              {patientClinic?.name?.value === "Other"
                ? patientClinic?.otherHospital
                : patientClinic?.name?.value}
            </section>
          </p>
        </Section>
        <Section title="Prescription Details">
          <PrescriptionDetailsSection />
        </Section>
        <ConsentAndAuthorization
          termsAndConditionsData={patientConsentAndAuth}
          handleOnSubmit={handleSubmit}
        >
          <ActionButtons
            back={{ label: "Back", link: "/patient/enroll" }}
            submit={{
              label: "Submit",
              loading,
              disabled: isDisabled,
            }}
          />
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default DeliveryDetailsSummary
