import { useStaticQuery, graphql } from "gatsby"

const ALL_COMPLETED_IMAGES = graphql`
  {
    complete: file(relativePath: { eq: "pages/enrollDoctor__complete.png" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medicines: file(relativePath: { eq: "complete__medicines.png" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const useCompletedImages = () => {
  const data = useStaticQuery(ALL_COMPLETED_IMAGES)

  return data
}
