import React from "react"
import FormCheckbox from "elements/Form/FormCheckbox"
import Section from "elements/Section"
import Message from "elements/Message"

import { Brand } from "elements/Brand"
import { contactThroughChoices } from "../utils/deliveryDetailsSchema"

const CommunicationPreferences = ({ values }) => {
  return (
    <Section title="Communication Preferences" id="communication-preferences">
      <Message color="light">
        Our team will contact you for updates on your enrollment status and
        deliveries. You may also opt-in to receiving educational materials on{" "}
        <Brand /> use and disease management over email.
      </Message>
      <FormCheckbox
        title="I want to be contacted through: "
        name="contactThrough"
        helper="Please choose one or more channels. Our team will contact you via SMS by default."
        values={values.contactThrough}
        options={contactThroughChoices}
        disabledOptions={["SMS"]}
        isRequired
      />
    </Section>
  )
}

export default CommunicationPreferences
