import React from "react"
import Section from "elements/Section"
import Message from "elements/Message"
import { Brand } from "elements/Brand"
import Address from "elements/Address"

const DeliveryAddressSection = ({ values, setFieldValue }) => {
  const deliveryAddressFieldNames = {
    addressType: `deliveryAddress.addressType`,
    streetAddress: `deliveryAddress.streetAddress`,
    city: `deliveryAddress.city`,
    province: `deliveryAddress.province`,
    notes: `deliveryAddress.notes`,
    barangay: `deliveryAddress.barangay`,
  }

  const isIncluded = {
    barangay: true,
  }

  return (
    <Section title="Delivery Address">
      <Message color="light">
        This information will be used for delivery of your <Brand /> samples.
      </Message>
      <Address
        fieldNames={deliveryAddressFieldNames}
        values={values.deliveryAddress}
        setFieldValue={setFieldValue}
        isRequired
        isNationwide
        isIncluded={isIncluded}
      />
    </Section>
  )
}

export default DeliveryAddressSection
