import { sendToZendesk } from "services/zendeskService"
import { GATSBY_ENV } from "gatsby-env-variables"
import { validationSchema } from "../utils/deliveryDetailsSchema"
import { handleError } from "../../../services/handleError"

export const shouldPatientButtonBeDisabled = async ({ formData }) =>
  !(await validationSchema.isValid({ ...formData }))

export const handleSendOrder = async config => {
  let { fileArray, ticketTemplate, values, callback, errorCallback } = config
  try {
    let isTest = GATSBY_ENV !== "production"

    let tags = ["patient_enrollment", "novo_nordisk", "ozempic"]
    if (isTest) tags.push("test")

    let subject = `${isTest ? "[TEST] " : ""} Patient Enrollment Form of ${
      values?.patient?.firstName
    } ${values?.patient?.lastName}`

    let requestBody = {
      type: "request",
      tags,
      subject,
      requester: {
        name: `${values?.patient?.firstName} ${values?.patient?.lastName}`,
        email: values?.patient?.email,
      },
      comment: { body: ticketTemplate({ values }) },
    }

    await sendToZendesk(requestBody, fileArray || [])
    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."

    handleError({ error, errorCallback, errorMessage })
  }
}
