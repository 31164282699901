import React from "react"

import Section from "elements/Section"
import FormInput from "elements/Form/FormInput"
import Message from "elements/Message"

const AuthorizedPersonSection = () => {
  return (
    <Section title="Secondary Contact Person">
      <Message color="light">
        We will coordinate with your authorized contact in case you are not
        available on the day of the delivery.
      </Message>
      <FormInput
        name="authorizedPerson.fullName"
        label="Full Name"
        isRequired
        placeholder="Juan Dela Cruz"
      />
      <FormInput
        name="authorizedPerson.contactNumber"
        label="Contact Number"
        placeholder="09123456789"
        isRequired
        maxLength={11}
        isNumeric
      />
    </Section>
  )
}

export default AuthorizedPersonSection
