import React, { useContext, useEffect, useState } from "react"

import Layout from "components/Layout/Layout"
import Container from "components/Layout/Container"
import DeliveryDetailsForm from "./DeliveryDetails/DeliveryDetailsForm"

import { AppContext } from "../../context/AppContext"
import { getUserById } from "../Doctor/services/patients"

const DeliveryDetails = () => {
  const { state } = useContext(AppContext)
  const { id, patientUid } = state?.eprescription
  const [patientData, setPatientData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchPatientData = async () => {
      let tempPatientData
      if (!state?.epharmacy?.patient?.id) {
        setLoading(true)
        tempPatientData = await getUserById({
          id: patientUid,
        })
      } else tempPatientData = { ...state?.epharmacy?.patient }
      setPatientData(tempPatientData)
      setLoading(false)
    }

    if (id && patientData?.id !== patientUid) fetchPatientData()
  }, [state, patientData, patientUid, id])

  return (
    <Layout
      title="Enroll as Patient"
      subtitle={
        <span>
          Please ensure that the information provided below is correct.
        </span>
      }
      seoTitle="Enroll as Patient"
    >
      <Container isCentered desktop={6} fullhd={6}>
        <DeliveryDetailsForm loading={loading} patientData={patientData} />
      </Container>
    </Layout>
  )
}

export default DeliveryDetails
