import React from "react"
import { Link } from "gatsby"
import { GATSBY_AWS_SES_EMAIL_TO } from "gatsby-env-variables"

import Completed from "../Completed"
import { ProgramName, Brand } from "elements/Brand"
import DeliveryDisclaimerMessage from "../Elements/DeliveryDisclaimerMessage"

const DeliveryDetailsCompleted = () => {
  return (
    <Completed title="Thank you!" seoTitle="Submission Completed">
      <p>
        Thank you for enrolling in the{" "}
        <ProgramName className="has-text-weight-bold has-text-primary" />.
      </p>
      <p>
        Your enrollment is currently being processed. Once we verify your
        eligibility, you will receive an SMS confirming your enrollment in one
        working day.
      </p>
      <p>
        Eligible patients under the <ProgramName /> will receive one (1)
        Semaglutide 0.25mg Pre-filled Pen and two (2) Semaglutide 0.5mg
        Pre-filled Pens which is equivalent for three months treatment with{" "}
        <Brand />. If your doctor prescribed in excess of this amount, you can
        purchase additional pens once <Brand /> becomes commercially available.
      </p>
      <p>
        Since the program has limited slots, enrollments will be taken on a
        first come, first served basis. Enrollments are not considered final
        until they have been verified by our team. <DeliveryDisclaimerMessage />
      </p>
      <p>
        Feel free to reach us at{" "}
        <a
          href={`mailto:${GATSBY_AWS_SES_EMAIL_TO}`}
          className="has-text-weight-bold"
        >
          {GATSBY_AWS_SES_EMAIL_TO}
        </a>{" "}
        or 0968 230 6545 for any questions.
      </p>
      <p className="has-text-centered">
        <Link to="/" className="button is-primary is-medium">
          Back to Home Page
        </Link>
      </p>
    </Completed>
  )
}

export default DeliveryDetailsCompleted
