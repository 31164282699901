import { faBuilding, faHome } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { Fragment, useContext } from "react"
import { AppContext } from "../../../context/AppContext"

const DeliveryAddressSummary = () => {
  const { state } = useContext(AppContext)
  const { deliveryAddress } = state.epharmacy
  const addressIcon =
    deliveryAddress?.addressType === "Home" ? faHome : faBuilding

  return (
    <Fragment>
      {deliveryAddress?.streetAddress && (
        <p>
          <span className="icon is-small mr-1">
            <FontAwesomeIcon icon={addressIcon} />
          </span>{" "}
          {deliveryAddress.streetAddress}, {deliveryAddress.city.value},{" "}
          {deliveryAddress.province.value}
        </p>
      )}
      {deliveryAddress.notes && (
        <p className="mb-1-mobile">
          Delivery Notes:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {deliveryAddress.notes}
          </span>
        </p>
      )}
    </Fragment>
  )
}

export default DeliveryAddressSummary
