export const epharmacyOrderZendeskTemplate = ({ values }) => {
  let {
    patient,
    deliveryAddress,
    authorizedPerson,
    doctor,
    prescribingDoctor,
    patientClinic,
    contactThrough,
    continueReceivingInformation,
    otherDiabetesMedications,
  } = values

  let doctorName = doctor?.id
    ? `${doctor?.firstName} ${doctor?.lastName}`
    : prescribingDoctor

  let prescriptionUploaded = values.uploadedFile
    ? `\n\nPrescription Uploaded: ${values.uploadedFile}`
    : ""

  let contactThroughString =
    contactThrough.length > 0 ? contactThrough.join(", ") : "N/A"

  let { birthday } = patient

  let parsedBirthday = `${birthday?.month?.value} ${birthday?.date?.value} ${birthday?.year}`

  let patientHospital =
    patientClinic?.name?.value !== "Other"
      ? patientClinic?.name?.value
      : patientClinic?.otherHospital

  return `Personal Details\nFirst Name: ${patient?.firstName}\nLast Name: ${
    patient?.lastName
  }\nEmail: ${patient?.email}\nMobile Number: ${
    patient?.mobileNumber
  }\nBirthday: ${parsedBirthday}\nPrescribing Doctor: ${doctorName}\nHospital/Clinic: ${patientHospital}\nAuthorized Person to receive: ${
    authorizedPerson.fullName
  }\nContact number of authorized person: ${
    authorizedPerson.contactNumber
  }\nI want to be contacted through: ${contactThroughString}\nOther diabetes medications: ${otherDiabetesMedications ||
    "N/A"}\nReceive materials from and share my enrollment data with EDUCARE: ${
    continueReceivingInformation?.length > 0 ? "YES" : "NO"
  }\n---\nDelivery Address\nStreet address: ${
    deliveryAddress?.streetAddress
  }\nCity/Municipality: ${deliveryAddress?.city.value}\nBarangay: ${
    deliveryAddress?.barangay.value
  }\nProvince: ${deliveryAddress?.province.value}\nAddress type: ${
    deliveryAddress?.addressType
  }\nDelivery notes: ${deliveryAddress?.notes || "N/A"}${prescriptionUploaded}`
}
