import React, { Fragment, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"

import { AppContext } from "../../../context/AppContext"

const PersonalInformationSummary = () => {
  const { state } = useContext(AppContext)
  const { patient } = state.epharmacy

  return (
    <Fragment>
      <p>
        <span className="has-text-weight-bold">
          {patient?.firstName} {patient?.lastName}
        </span>
      </p>
      <p>
        <span>
          <FontAwesomeIcon className="mr-1" icon={faPhone} />{" "}
          {patient?.mobileNumber}
        </span>
      </p>
      <p>
        <span>
          <FontAwesomeIcon className="mr-1" icon={faEnvelope} />{" "}
          {patient?.email}
        </span>
      </p>
    </Fragment>
  )
}

export default PersonalInformationSummary
