import React from "react"

import FormSelect from "elements/Form/FormSelect"

import {
  handleLoadHospitalOptions,
  handleOnHospitalChange,
} from "./services/hospitals"
import hospitals from "./utils/hospitals.json"

const HospitalSelect = ({ name, value, isRequired }) => {
  return (
    <FormSelect
      name={name}
      options={hospitals}
      value={value}
      helper="Please select 'Other' if you can't find your hospital/clinic in the list."
      label="Hospital/Clinic"
      isRequired={isRequired}
      placeholder="Philippine General Hospital"
      loadOptions={handleLoadHospitalOptions}
      onChange={handleOnHospitalChange(name)}
      defaultOptions
      isAsync
      hideOptional={!isRequired}
    />
  )
}

export default HospitalSelect
