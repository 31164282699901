import React from "react"

import Collapsible from "elements/Collapsible"
import { upload_guidelines } from "../../../static/data/uploadGuidelines.json"

const UploadGuidelines = () => {
  return (
    <Collapsible title="Upload Guidelines" color="light">
      <ol>
        {upload_guidelines.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ol>
    </Collapsible>
  )
}

export default UploadGuidelines
