import React, { Fragment, useContext } from "react"

import FormInput from "elements/Form/FormInput"
import FileURLPreview from "elements/FileURLPreview"
import UploadGuidelines from "elements/UploadGuidelines"
import HospitalSelect from "elements/Hospitals/HospitalSelect"
import UploadPrescriptionSection from "elements/UploadPrescriptionSection"

import { AppContext } from "../../../context/AppContext"
import { PrescribedMedicinesList } from "components/Eprescription/EprescriptionForm/PrescribedMedicinesSection"

const PrescriptionDetailsSection = ({ submitCount, values }) => {
  const { state } = useContext(AppContext)
  const { medicines, id, uploadedFile } = state?.eprescription
  const hasMedicinesPrescribed = medicines?.length > 0

  if (uploadedFile) {
    return (
      <Fragment>
        <FileURLPreview file={uploadedFile} />
        <section className="mb-2">
          <HospitalSelect
            name={`patientClinic.name`}
            value={values?.patientClinic?.name}
            isRequired
          />
          {values?.patientClinic?.name?.value === "Other" && (
            <FormInput
              name={`patientClinic.otherHospital`}
              label="Please indicate your hospital/clinic"
              placeholder="Philippine General Hospital"
              isRequired
            />
          )}
        </section>
      </Fragment>
    )
  }
  if (id)
    return (
      <Fragment>
        <section className="mb-2">
          <PrescribedMedicinesList
            hasMedicinesPrescribed={hasMedicinesPrescribed}
            medicines={medicines}
          />
        </section>
        <section className="mb-2">
          <HospitalSelect
            name={`patientClinic.name`}
            value={values?.patientClinic?.name}
            isRequired
          />
          {values?.patientClinic?.name?.value === "Other" && (
            <FormInput
              name={`patientClinic.otherHospital`}
              label="Please indicate your hospital/clinic"
              placeholder="Philippine General Hospital"
              isRequired
            />
          )}
        </section>
      </Fragment>
    )
  return (
    <Fragment>
      <FormInput
        name="prescribingDoctor"
        label="Prescribing Doctor"
        placeholder="Juana Dela Cruz"
        isRequired
      />
      <HospitalSelect
        name={`patientClinic.name`}
        value={values?.patientClinic?.name}
        isRequired
      />
      {values?.patientClinic?.name?.value === "Other" && (
        <FormInput
          name={`patientClinic.otherHospital`}
          label="Please indicate your hospital/clinic"
          placeholder="Philippine General Hospital"
          isRequired
        />
      )}
      <section className="mb-2">
        <p className="is-size-5 mb-1">Upload Prescription</p>
        <UploadGuidelines />
        <UploadPrescriptionSection />
        {submitCount > 0 && state.documents.length < 1 && (
          <p className="help mt-0 mb-1 is-danger">
            Please upload your prescription to proceed
          </p>
        )}
      </section>
    </Fragment>
  )
}

export default PrescriptionDetailsSection
