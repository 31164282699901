import React, { useContext } from "react"
// import classNames from "classnames"
import { Formik, Form } from "formik"

import Section from "elements/Section"
import FormCheckbox from "elements/Form/FormCheckbox"

// import styles from "./utils/elements.module.scss"
import { validationSchema } from "./utils/consentAndAuthSchema"
import { AppContext } from "../../context/AppContext"
import { consentAndAuth } from "../../context/AppReducer"

// const ConsentAndAuthSection = ({ title, data }) => (
//   <Fragment>
//     <h6 className="mt-0">{title}</h6>
//     <ul className="help">
//       {data.map(item => (
//         <li dangerouslySetInnerHTML={{ __html: item }} />
//       ))}
//     </ul>
//   </Fragment>
// )

const termsAndConditionBlurb = (
  <span>
    I agree to the{" "}
    <a href="/terms-and-conditions" target="__blank">
      terms and conditions
    </a>
    .
  </span>
)

const ConsentAndAuthorization = ({
  children,
  handleOnSubmit,
  termsAndConditionsData,
}) => {
  const { state, dispatch } = useContext(AppContext)

  return (
    <Section title="Consent and Authorization">
      <div className="mx-2 mx-0-mobile">
        {/* {termsAndConditionsData && (
          <article className="message">
            <div className={classNames("message-body", styles["collapsible"])}>
              <section className="content">
                {Object.keys(termsAndConditionsData || {}).map(key => (
                  <ConsentAndAuthSection
                    title={termsAndConditionsData[key].title}
                    data={termsAndConditionsData[key].info}
                  />
                ))}
              </section>
            </div>
          </article>
        )} */}
        <Formik
          initialValues={{ agreeToConsent: state.consentAndAuth }}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <FormCheckbox
                name="agreeToConsent"
                values={values.agreeToConsent}
                options={[termsAndConditionBlurb]}
                onChange={event =>
                  dispatch({
                    type: consentAndAuth.SAVE_CONSENT_AND_AUTH,
                    payload: event.target.checked
                      ? [termsAndConditionBlurb]
                      : [],
                  })
                }
              />
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization
