import React, { Fragment, useState, useEffect } from "react"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"
import FormTextArea from "elements/Form/FormTextArea"

import address from "../../../../static/data/philippineLocations.json"
import AddressTypeChoices from "./AddressTypeChoices.js"

const INPUT_STREET_PLACEHOLDER = "Unit/House No., Building, Street, Barangay"
const SELECT_CITY_PLACEHOLDER = "Select city..."
const SELECT_BARANGAY_PLACEHOLDER = "Select barangay..."

const Address = ({
  isRequired,
  fieldNames = {},
  values = {},
  isNationwide,
  helper,
  setFieldValue,
  isIncluded,
}) => {
  const {
    addressType,
    streetAddress,
    province,
    city,
    barangay,
    notes,
  } = fieldNames
  const [listOfProvince, setProvinces] = useState([])
  const [listOfCity, setListOfCity] = useState([])
  const [listOfBarangay, setListOfBarangay] = useState([])
  const [currentProvince, setCurrentProvince] = useState("")

  useEffect(() => {
    initializeProvinces()
    const { Cities } = address.filter(loc => loc.Province === "Metro Manila")[0]
    setListOfCity(sortCities(Cities))
    setListOfBarangay(sortBarangays(Cities[0]?.Barangays))
    setCurrentProvince("Metro Manila")
    //eslint-disable-next-line
  }, [])

  const initializeProvinces = () => {
    let options = address.map(remapProvinces)
    const metroManila = options.filter(({ value }) => value === "Metro Manila")
    if (isNationwide) setProvinces(options)
    else setProvinces(metroManila)
  }

  const remapProvinces = ({ Province }) => ({
    value: Province,
    label: Province,
  })

  const sortBarangays = barangays => {
    const sortedBarangays = barangays
      .map(barangay => ({
        value: barangay,
        label: barangay,
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1))
    return sortedBarangays
  }

  const sortCities = cities => {
    const sortedCities = cities
      .map(({ City }) => ({
        value: City,
        label: City,
      }))
      .sort((a, b) => {
        return a.value > b.value ? 1 : -1
      })

    return sortedCities
  }
  const handleOnChange = ({ value }, setFieldValue) => {
    const { Cities } = address.filter(loc => loc.Province === value)[0]
    setListOfCity(sortCities(Cities))
    setListOfBarangay(sortBarangays(Cities[0]?.Barangays))
    setFieldValue(city || "city", "")
    setFieldValue(barangay || "barangay", "")
    setCurrentProvince(value)
  }

  const handleOnCityChange = ({ value }, setFieldValue) => {
    const { Cities } = address.filter(
      loc => loc.Province === currentProvince
    )[0]
    const barangays = Cities.filter(city => city.City === value)[0]?.Barangays
    setListOfBarangay(sortBarangays(barangays))
    setFieldValue(barangay || "barangay", "")
  }

  return (
    <Fragment>
      {addressType && (
        <AddressTypeChoices
          addressTypeName={addressType}
          values={values}
          setFieldValue={setFieldValue}
          isRequired={isRequired}
        />
      )}
      <FormInput
        name={streetAddress || "streetAddress"}
        label="Street Address"
        helper={helper}
        placeholder={INPUT_STREET_PLACEHOLDER}
        isRequired={isRequired}
      />
      <FormSelect
        name={province || "province"}
        label="Province"
        isRequired={isRequired}
        value={values.province}
        options={listOfProvince}
        onChange={handleOnChange}
      />
      <FormSelect
        isClearable={true}
        name={city || "city"}
        label="City"
        placeholder={SELECT_CITY_PLACEHOLDER}
        value={values.city}
        isRequired={isRequired}
        options={listOfCity}
        onChange={handleOnCityChange}
      />
      {isIncluded?.barangay && (
        <FormSelect
          isClearable={true}
          name={barangay || "barangay"}
          label="Barangay"
          placeholder={SELECT_BARANGAY_PLACEHOLDER}
          value={values.barangay}
          isRequired={isRequired}
          options={listOfBarangay}
          isSelectable
        />
      )}
      {notes && <FormTextArea name={notes || "notes"} label="Delivery Notes" />}
    </Fragment>
  )
}

export default Address
