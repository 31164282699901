import { faPhone, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext } from "react"
import { AppContext } from "../../../context/AppContext"

const AuthorizedPersonSummary = () => {
  const { state } = useContext(AppContext)
  const { authorizedPerson } = state.epharmacy

  if (authorizedPerson.fullName)
    return (
      <p className="mt-1 mb-1-mobile">
        Secondary Contact Person
        <div className="has-text-weight-bold">
          <FontAwesomeIcon className="mr-1" icon={faUser} />
          {authorizedPerson?.fullName}
        </div>
        <div>
          {" "}
          <FontAwesomeIcon className="mr-1" icon={faPhone} />
          {authorizedPerson?.contactNumber || "N/A"}
        </div>
      </p>
    )
  return null
}

export default AuthorizedPersonSummary
